import React, { useState, useEffect } from "react";
import FieldValidation from "../../components/Common/FieldValidation";
import PageHeader from "../../components/Common/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../../store/Auth/actions";
import { getUserDetails } from "../../helpers/repository";
import { apiError } from "../../store/actions";

function Profile(props) {
  const [marketing_opt_in, set_marketing_opt_in] = useState("mobile");
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [email, set_email] = useState("");
  const [nin, set_nin] = useState("");

  const dispatch = useDispatch();
  const formErrors = useSelector((state) => state?.CommonState);

  useEffect(() => {
    dispatch(apiError(""));
    getUserDetails().then((res) => {
      set_first_name(res?.data?.data?.first_name || "");
      set_last_name(res?.data?.data?.last_name || "");
      set_email(res?.data?.data?.email || "");
      set_nin(res?.data?.data?.nin || "");
    });
  }, []);

  const removeError = (key) => {
    let e = _.cloneDeep(formErrors);
    delete e[key];
    dispatch(apiError(e));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      nin: nin,
    };

    // Final NIN check => must be exactly 14 chars, start with "CM" or "CF"
    const ninRegex = /^(CM|CF)[A-Z0-9]{12}$/;
    if (!ninRegex.test(nin)) {
      dispatch(
        apiError({
          ...formErrors,
          nin: "NIN not valid!.",
        })
      );
      return;
    }

    dispatch(updateProfile(data));
  };

  return (
    <>
      <PageHeader title={"Profile"} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="BLM-loginRegister BLM-login">
            <div className="BLM-content">
              <form onSubmit={handleSubmit}>

                {/* First Name */}
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Enter First Name:</div>
                  <div className="BLM-form-control">
                    <input
                      type="text"
                      id="userName"
                      required
                      onChange={(e) => {
                        removeError("first_name");
                        set_first_name(e.target.value);
                      }}
                      value={first_name}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.first_name ? (
                      <FieldValidation errorMessage={formErrors?.first_name} />
                    ) : null}
                  </div>
                </div>

                {/* Last Name */}
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Enter Last Name:</div>
                  <div className="BLM-form-control">
                    <input
                      type="text"
                      id="userLastName"
                      onChange={(e) => {
                        removeError("last_name");
                        set_last_name(e.target.value);
                      }}
                      value={last_name}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.last_name ? (
                      <FieldValidation errorMessage={formErrors?.last_name} />
                    ) : null}
                  </div>
                </div>

                {/* Email */}
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Email:</div>
                  <div className="BLM-form-control">
                    <input
                      type="email"
                      id="userEmail"
                      onChange={(e) => {
                        removeError("email");
                        set_email(e.target.value);
                      }}
                      value={email}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.email ? (
                      <FieldValidation errorMessage={formErrors?.email} />
                    ) : null}
                  </div>
                </div>

                {/* NIN */}
                <div className="BLM-form-group">
                  <div className="BLM-form-label">NIN:</div>
                  <div className="BLM-form-control BLM-form-control-pin">
                    <input
                      type="text"
                      id="nin"
                      placeholder="Enter NiN - all CAPS"
                      required
                      onChange={(e) => {
                        removeError("nin");

                        // Enforce uppercase & only letters/digits
                        let newValue = e.target.value
                          .toUpperCase()
                          .replace(/[^A-Z0-9]/g, "");

                        // Limit length to 14
                        if (newValue.length > 14) {
                          newValue = newValue.slice(0, 14);
                        }

                        // If user is deleting (shorter newValue), allow it
                        if (newValue.length < nin.length) {
                          set_nin(newValue);
                          return;
                        }

                        // If user is adding, check partial regex
                        const partialRegex = /^C(M|F)?[A-Z0-9]{0,12}$/;
                        if (partialRegex.test(newValue) || newValue === "") {
                          set_nin(newValue);
                        }
                        // If not valid, do nothing => old value remains
                      }}
                      onPaste={(e) => e.preventDefault()} // Prevent paste
                      value={nin}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.nin ? (
                      <FieldValidation errorMessage={formErrors?.nin} />
                    ) : null}
                  </div>
                </div>

                {/* Marketing Preference */}
                <div className="BLM-radioBoxGroup">
                  <input
                    className="BLM-radioBox"
                    type="radio"
                    name="mobile"
                    id="mobile"
                    value="mobile"
                    onChange={(e) => set_marketing_opt_in(e.target.value)}
                    checked={marketing_opt_in === "mobile"}
                  />
                  <label className="form-check-label BLM-radioBox" htmlFor="mobile">
                    mobile
                  </label>

                  <input
                    className="BLM-radioBox"
                    type="radio"
                    name="online"
                    id="online"
                    value="online"
                    onChange={(e) => set_marketing_opt_in(e.target.value)}
                    checked={marketing_opt_in === "online"}
                  />
                  <label className="form-check-label BLM-radioBox" htmlFor="online">
                    online
                  </label>

                  <input
                    className="BLM-radioBox"
                    type="radio"
                    name="sms"
                    id="sms"
                    value="sms"
                    onChange={(e) => set_marketing_opt_in(e.target.value)}
                    checked={marketing_opt_in === "sms"}
                  />
                  <label className="form-check-label BLM-radioBox" htmlFor="sms">
                    sms
                  </label>

                  <input
                    className="BLM-radioBox"
                    type="radio"
                    name="telegram"
                    id="telegram"
                    value="telegram"
                    onChange={(e) => set_marketing_opt_in(e.target.value)}
                    checked={marketing_opt_in === "telegram"}
                  />
                  <label className="form-check-label BLM-radioBox" htmlFor="telegram">
                    telegram
                  </label>
                </div>

                <div className="space-10" />

                <button
                  className="btn BLM-btnSecondary active BLM-btnLarge"
                  id="disableLoginButtonClick"
                  type="submit"
                >
                  <span className="BLM-btnTxt">Update Profile</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
