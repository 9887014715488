import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import ReactModal from "react-modal";
import { withRouter } from "react-router-dom";
import {
  BlockedGames,
  getAllCasinoGames,
  getAviatorGameCall,
} from "../../helpers/repository";

function Spribe(props) {
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;
  const [iframeURL, setIframeURL] = useState(null);
  const [show, setShow] = useState(false);
  const demoText = isLoggedIn == true ? "Play" : "Demo";

  const games = [
    "Dice.png",
    "Goal.png",
    "Plinko.png",
    "Mines.png",
    "Hi Lo.png",
    "Keno.png",
    "Mini Roulette.png",
    "Hotline.png",
    "Balloon.png",
    "Keno 80.png",
  ];
  const gamesCode = [
    "dice",
    "goal",
    "plinko",
    "mines",
    "hi-lo",
    "keno",
    "mini-roulette",
    "hotline",
    "balloon",
    "multikeno",
  ];
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "67%!important",
      overflow: "hidden",
      padding: "20px 20px 20px 20px",
    },
  };

  const _openGameUrl = (game) => {
    $(".ReactModal__Overlay").show();

    getAviatorGameCall(game, successGetGameUrl, errorGetGameUrl);
  };

  // useEffect(() => {
  //     BlockedGames().then((res) => {
  //         setIframeURL(res);
  //     }).catch((e) => {
  //         if(e?.response?.data?.code=="restrict"){
  //             setShow(true)
  //             // props.history.push("/comming-soon");
  //         }
  //     });
  // }, []);

  const successGetGameUrl = (res) => {
    setIframeURL(decodeURIComponent(res));
  };

  const errorGetGameUrl = (error) => {
    showMessage("error", error);
  };
  return (
    <>
      <div className="BLM-commonPage" style={{ "background-color": "#ffffff" }}>
        <div
          className="BLM-commonPage-content BLM-common"
          style={{ padding: "0px 22px 22px 22px", marginBottom: "unset" }}
        >
          {show ? (
            <div style={{ textAlign: "center", padding: "88px" }}>
              <h5>No Data Available</h5>
            </div>
          ) : (
            <div className="row img_row img_row1">
              <ReactModal
                // style={customStyles}
                isOpen={iframeURL}
                onRequestClose={() => setIframeURL(null)}
                className="Turbo_game"
              >
                <Iframe
                  url={iframeURL}
                  // width="450px"
                  // height="685px"
                  id="myId"
                  className="myClassname turbo_game_iframe"
                  display="initial"
                  position="relative"
                  overflow="hidden"
                />
              </ReactModal>
              {games?.map((game, i) => (
                <div
                  className={`col-lg-3 col-md-4 col-6 mt-2 position-relative ${
                    i % 2 !== 0 ? "image-padding-set" : ""
                  }`}
                >
                  <img
                    className={"games_turbo"}
                    style={{
                      width: "100%",
                      height: "100%",
                      "padding-top": "15px",
                    }}
                    src={`/assets/images/spribeicons/${game}`}
                    onClick={() => {
                      _openGameUrl(gamesCode[i]);
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withRouter(Spribe);
