import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import { imagePath } from "../../helpers/constants";
function Banners(props) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const _renderBanner = () => {
    return props.Banner.map((img, i) => {
      return (
        <div className="banner-item" key={img?.id}>
           <a href="https://betsports.ug/promotions">
          <img src={imagePath("banners", img?.banner)} /></a>
        </div>
      );
    });
  };

  return (
    <>
      <Slider className="menu" {...settings}>
        {_renderBanner()}
      </Slider>
    </>
  );
}

export default Banners;
